import { useState } from "react"
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import contactImg from "../assets/img/contact-img.svg"
import emailjs from 'emailjs-com';
import { useTranslation } from "react-i18next";



export const Contact = () => {
    const {t} = useTranslation();

    const fomrInitialDetails ={
        firstName:"",
        lastName:"",
        email:"",
        message:""

    }
    const [formDetails,setFormDetails] = useState(fomrInitialDetails)
    const [buttonText,setButtonText] = useState("Send Message")
    const [status,setStatus] = useState({})

    const onFormUpdate=(category,value)=>{
        setFormDetails({...formDetails,[category]:value})
    }
    function sendEmail(e) {
        e.preventDefault();
        setButtonText("Sending...")
        emailjs.sendForm('service_xuolkb6', 'template_2goklnd', e.target, 'XMEjjiFBAqpn1aalj')
      .then((result) => {
          setStatus({succes:"true",message:"Message Sent Successfully"})
          setButtonText("Send Message")

          console.log(result.text);
      }, (error) => {
        setStatus({succes:"false",message:"Message Not Sent"})
        setButtonText("Send Message")

          console.log(error.text);
      });
      
        e.target.reset()
    }

    return (
            <section className="contact" id="contact">
                <Container>
                    <Row className="align-items-center">
                        <Col md={6}>
                            <img src={contactImg} alt="contact-img"  />
                        </Col>
                        <Col md={6}>
                            <h2>{t('trad.contact.title')}</h2>
                            <form onSubmit={sendEmail}>
                                <Row>
                                    <Col sm={6} className="px-1">
                                    <input type="text" placeholder={t('trad.contact.firstName')} value={formDetails.fisrtName}  onChange={(e) => onFormUpdate('firstName',e.target.value)  } name="firstName" required/>
                                    </Col>
                                    <Col sm={6} className="px-1">
                                    <input type="text" placeholder={t('trad.contact.lastName')} value={formDetails.lastName}  onChange={(e) => onFormUpdate('lastName',e.target.value) } name="lastName" required/>
                                    </Col>
                                    <Col sm={12} className="px-1">
                                    <input type="email" placeholder={t('trad.contact.email')} value={formDetails.email}  onChange={(e) => onFormUpdate('email',e.target.value) } name="email" required/>
                                    </Col>
                                    <Col>
                                    <textarea row="6" placeholder="Message" value={formDetails.message}  onChange={(e) => onFormUpdate('message',e.target.value) } name="message" required></textarea>
                                    <button type="submit" ><span>{buttonText}</span> </button>
                                    </Col>
                                   {
                                    status.message && 
                                    <Col>
                                        <p className={status.succes === false ? "danger": "succes" }>{status.message} </p> 
                                    </Col>
                                   }

                                    

                                </Row>
                            </form>

                            </Col>
                        </Row>
                </Container>

                </section>
    )
}