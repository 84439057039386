import { Col } from "react-bootstrap";

export const ProjectCard = ({ title, description, imageUrl, link }) => {
  return (
    <Col sm={6} md={4}>
      {link ? (
        <a href={link} className="project-card" target="blank">
          <div className="proj-imgbx">
            <img src={imageUrl} alt="project-img" />
            <div className="proj-txtx">
              <h4>{title}</h4>
              <span>{description}</span>
            </div>
          </div>
        </a>
      ) : (
        <div className="project-card">
          <div className="proj-imgbx">
            <img src={imageUrl} alt="project-img" />
            <div className="proj-txtx">
              <h4>{title}</h4>
              <span>{description}</span>
            </div>
          </div>
        </div>
      )}
    </Col>
  );
};
