import {Container,Row , Col } from "react-bootstrap"
import logo from "../assets/img/logo.svg"
import mehdiLogo from "../assets/img/Mehdi-logo.png"
import navIcon1 from '../assets/img/nav-icon1.svg'
import navIcon2 from '../assets/img/nav-icon2.svg'


export const Footer = () => {
    return (
        <footer className="footer">
            <Container>
                <Row className="align-item-center">
                    <Col sm={9}>
                        <img src={mehdiLogo} alt="logo" className="logo"></img>
                    </Col>
                    <Col sù={3}>
                        <div className="social-icon">
                            <a href="https://www.linkedin.com/in/mehdi-boussalem-49666b252/" target="blank"><img src={navIcon1}></img></a>
                            <a href="https://github.com/MehdiBoussalem?tab=repositories" target="blank"><img src={navIcon2}></img></a>
                        </div>
                        <p>Copyright © Mehdi Boussalem 2022.  </p>
                    </Col>
                </Row>

            </Container>
        </footer>
    )
}