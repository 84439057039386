import { Container, Row, Col } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import colorSharp from "../assets/img/color-sharp.png";
import python from "../assets/img/python.svg";
import french from "../assets/img/french-flag.png";
import english from "../assets/img/english-flag.png";
import java from "../assets/img/java.svg";
import c from "../assets/img/C.svg";
import php from "../assets/img/php.svg";
import reactImg from "../assets/img/react.svg";
import latex from "../assets/img/latex.svg";
import office from "../assets/img/office.svg";
import html from "../assets/img/html.svg";
import js from "../assets/img/Javascript.svg";
import git from "../assets/img/git.svg";
import sql from "../assets/img/sql.svg";
import shell from "../assets/img/shell.svg";
import car from "../assets/img/car.svg";

import { useTranslation } from "react-i18next";

export const Skills = () => {
  const { t } = useTranslation();

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section className="skills" id="skills">
      <Container>
        <Row>
          <Col>
            <div className="skill-bx">
              <h2>{t("trad.skills.title")}</h2>
              <p>{t("trad.skills.text")}</p>
              <Carousel
                responsive={responsive}
                infinite={true}
                className="skill-slider"
              >
                <div className="item">
                  <img src={python} alt="Image" />
                  <h5>{t("trad.skills.advanced")}</h5>
                </div>
                <div className="item">
                  <img src={french} alt="Image" />
                  <h5>{t("trad.skills.native")}</h5>
                </div>
                <div className="item">
                  <img src={english} alt="Image" />
                  <h5>{t("trad.skills.advanced")}</h5>
                </div>
                <div className="item">
                  <img src={java} alt="Image" />
                  <h5>{t("trad.skills.advanced")}</h5>
                </div>
                <div className="item">
                  <img src={c} alt="Image" />
                  <h5>{t("trad.skills.intermediate")}</h5>
                </div>
                <div className="item">
                  <img src={php} alt="Image" />
                  <h5>{t("trad.skills.intermediate")}</h5>
                </div>
                <div className="item">
                  <img src={reactImg} alt="Image" />
                  <h5>{t("trad.skills.intermediate")}</h5>
                </div>
                <div className="item">
                  <img src={latex} alt="Image" />
                  <h5>{t("trad.skills.intermediate")}</h5>
                </div>
                <div className="item">
                  <img src={office} alt="Image" />
                  <h5>{t("trad.skills.advanced")}</h5>
                </div>
                <div className="item">
                  <img src={html} alt="Image" />
                  <h5>{t("trad.skills.advanced")}</h5>
                </div>
                <div className="item">
                  <img src={js} alt="Image" />
                  <h5>{t("trad.skills.intermediate")}</h5>
                </div>
                <div className="item">
                  <img src={git} alt="Image" />
                  <h5>{t("trad.skills.advanced")}</h5>
                </div>
                <div className="item">
                  <img src={sql} alt="Image" />
                  <h5>{t("trad.skills.formation")}</h5>
                </div>
                <div className="item">
                  <img src={shell} alt="Image" />
                  <h5>{t("trad.skills.advanced")}</h5>
                </div>
                <div className="item">
                  <img src={car} alt="Image" />
                  <h5>{t("trad.skills.permis")}</h5>
                </div>
              </Carousel>
            </div>
          </Col>
        </Row>
      </Container>
      <img className="background-image-left" src={colorSharp} alt="Image" />
    </section>
  );
};
