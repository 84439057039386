import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { ArrowDownCircle } from "react-bootstrap-icons";
import headerimg from "../assets/img/header-img.svg";
import { useTranslation } from "react-i18next";
import CV from "../assets/Mehdi_Boussalem.pdf";

export const Banner = () => {
  const { t } = useTranslation();
  const Student = t("trad.banner.student");

  const [loopNumber, setLoopNumber] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const toRotate = [Student, Student];
  const [text, setText] = useState("");
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const period = 2000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);
    return () => {
      clearInterval(ticker);
    };
  }, [text]);

  const tick = () => {
    let i = loopNumber % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting
      ? fullText.substring(0, text.length - 1)
      : fullText.substring(0, text.length + 1);

    setText(updatedText);
    if (isDeleting) {
      setDelta((prevDelta) => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setDelta(period);
    } else if (isDeleting && updatedText === "") {
      setIsDeleting(false);
      setLoopNumber(loopNumber + 1);
      setDelta(300 - Math.random() * 100);
    }
  };

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="align-items-center">
          <Col xs={12} md={6} xl={7}>
            <span className="tagline">{t("trad.banner.tagline")}</span>
            <h1>
              {" "}
              {t("trad.banner.title")}
              <span className="wrap"> {text}</span>
            </h1>
            <p>{t("trad.banner.text")}</p>
            <a href={CV} download="Mehdi-Boussalem.pdf">
              <button>
                {t("trad.banner.button")}
                <ArrowDownCircle size={25} />{" "}
              </button>{" "}
            </a>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <img src={headerimg} alt="Header Img" />
          </Col>
        </Row>
      </Container>
    </section>
  );
};
