import i18next from 'i18next';
import { initReactI18next} from 'react-i18next';
import languageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';


const languages = ['en', 'fr'];



i18next
.use(Backend)
.use(languageDetector)
.use(initReactI18next)
.init({
  fallbackLng: "fr",
  debug: false,
  whitelist: languages,
  detection: {
    order: ['path', 'cookie', 'htmlTag'],
    caches: ['cookie','localStorage']
  },
  backend: {
    loadPath: '/locales/{{lng}}/translation.json',
  },
  react: {
    useSuspense: true,
  }
})
