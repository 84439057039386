import { useState,useEffect} from "react";
import { Navbar,Container,Nav } from "react-bootstrap";
import logo from '../assets/img/logo.svg'
import mehdiLogo from "../assets/img/Mehdi-logo-nav.png"

import navIcon1 from '../assets/img/nav-icon1.svg'
import navIcon2 from '../assets/img/nav-icon2.svg'
import { useTranslation } from "react-i18next";






export const NavBar = () => {
    const [activeLink, setActiveLink] = useState("home");
    const [scrolled, setScrolled] = useState(false);
    useEffect(() => {
        const onScroll = () => {
            if (window.scrollY > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        }
        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, []);
    const onUpdateActiveLink = (value) => {
        setActiveLink(value);
    }
   
   
   
    const {i18n ,t} = useTranslation();

    function clickLanguage() {
      if (i18n.language === 'fr') {
          i18n.changeLanguage('en');
      } else {
          i18n.changeLanguage('fr');
      }
      console.log("switching to " + i18n.language);
    }

    function Lang(){
      if (i18n.language === 'fr') {
        return <span>English Version </span>
    }
    else{
        return <span>Version Française </span>
    }

    }
  return (
    <Navbar  expand="lg" className={scrolled ? "scrolled": ""}>
      <Container>
        <Navbar.Brand href="#home">
            <img src={mehdiLogo} alt="logo" className="logo-home" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" > 
        <span className="navbar-toggler-icon"></span>
        </Navbar.Toggle> 
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="#home" className={activeLink ==='home' ? 'active navbar-link': 'navbar-link'} onClick={() => onUpdateActiveLink('home') }>{t('trad.nav.home')}</Nav.Link>
            <Nav.Link href="#skills" className={activeLink ==='skills' ? 'active navbar-link': 'navbar-link' } onClick={() => onUpdateActiveLink('skills') } >{t('trad.nav.skills')}</Nav.Link>
            <Nav.Link href="#projects" className={activeLink ==='projects' ? 'active navbar-link': 'navbar-link'} onClick={() => onUpdateActiveLink('projects') }>{t('trad.nav.projects')}</Nav.Link>
            <Nav.Link href="#resume" className={activeLink ==='projects' ? 'active navbar-link': 'navbar-link'} onClick={() => onUpdateActiveLink('resume') }>{t('trad.nav.resume')}</Nav.Link>
            <Nav.Link href="#contact" className={activeLink ==='contact' ? 'active navbar-link': 'navbar-link'} onClick={() => onUpdateActiveLink('contact') }>{t('trad.nav.contact')}</Nav.Link>
            
          </Nav>
          <span className="navbar-text">
              <div className="social-icon">
                <a href="https://www.linkedin.com/in/mehdi-boussalem-49666b252/" target="blank"><img src={navIcon1} alt="Linkedin-logo" /></a>
                <a href="https://github.com/MehdiBoussalem?tab=repositories" target="blank"><img src={navIcon2} alt="Github-logo" /></a>
              </div>
            <button className="vvd" onClick={( ) =>clickLanguage()}><Lang/></button>
            </span>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}


    
